import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";

const initialFieldValues = {
    agentCreditId: "00000000-0000-0000-0000-000000000000",
    userId: "00000000-0000-0000-0000-000000000000",
    amount: 0,
    available: 0,
    type: "",
    purpose: "",
    source: ""
};
export default function AgentCredits() {
    const [agentCredits, setAgentCredits] = useState([]);
    const [agents, setAgents] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [availableAmount, setAvailableAmount] = useState(0);
    const [pendingAmount, setPendingAmount] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        if (e.target.name === "userId" && e.target.value !=="00000000-0000-0000-0000-000000000000") {
            GetAgentCredits(e.target.value);
        }
    };
    const validate = () => {
        let temp = {};
        temp.userId = values.userId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.amount = values.amount === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("agentCreditId", values.agentCreditId)
            formData.append("userId", values.userId)
            formData.append("amount", values.amount)
            formData.append("available", values.available)
            formData.append("type", "CREDIT")
            formData.append("purpose", "NEW CREDIT LINE")
            formData.append("source", "PORTAL")
            formData.append("transactionDate", moment(new Date).format('YYYY-MM-DD'))
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.UPDATECREDIT, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .create(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Created");
                    resetForm();
                    if (values.userId !== "00000000-0000-0000-0000-000000000000") {
                        GetAgentCredits(values.userId);
                    }
                    setBtnSubmit(true);
                }
                else {
                    handleError(res.data.message);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const GetAgentCredits = (userId) => {
        axios
            .get(config.APIACTIVATEURL + config.GETAGENTCREDITBYAGENTS + "?userId=" + userId, { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded !== false) {
                    setAgentCredits(response.data.response.data);
                    const myList = response.data.response.data;
                    const firstRecord = myList.length > 0 ? myList[myList.length -1] : null;
                    if (firstRecord != null) {
                        setAvailableAmount(firstRecord.available);
                    }
                }
            });
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEAGENTCREDIT + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetAgentCredits(values.userId);
                    }
                    else {
                        handleError(response.data.data);
                        GetAgentCredits(values.userId);
                    }
                });
        }
    }
    const GetAgents = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETAGENTS, { ...headerconfig })
            .then((response) => {
                if (response.data.response != null) {
                    setAgents(response.data.response.data);
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetAgents();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Agent Credits</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="userId" className="form-label">Agency Name</label>
                                            <select name="userId" value={values.userId} onChange={handleInputChange} className={"form-select" + applyErrorClass('userId')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                {agents.map(o => (
                                                    <option key={o.userId} value={o.userId}>{o.agencyName}</option>
                                                ))}
                                            </select>
                                            {errors.userId === false ? (<div className="validationerror">Please select agency </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">Amount</label>
                                            <input type="text" value={values.amount} name="amount" onChange={handleInputChange} className={"form-control" + applyErrorClass('amount')} placeholder="0" />
                                            {errors.amount === false ? (<div className="validationerror">Please enter amount </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <label htmlFor="availableAmount" className="form-label">Credit Balance</label>
                                            <input type="text" disabled value={availableAmount} name="availableAmount" className={"form-control" + applyErrorClass('availableAmount')} placeholder="0" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                            <button type="button" className="btn btn-danger" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Agent Credits</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Amount</th>
                                                        <th>Available Amount</th>
                                                        <th>Type</th>
                                                        <th>Source</th>
                                                        <th>Purpose</th>
                                                        <th>Transaction Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {agentCredits.length > 0 && agentCredits.map((v, index) =>
                                                        <tr key={v.agentCreditId}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.amount}</td>
                                                            <td>{v.available}</td>
                                                            <td>{v.type}</td>
                                                            <td>{v.source}</td>
                                                            <td>{v.purpose}</td>
                                                            <td>{moment.utc(v.transactionDate).local().format('DD MMM YYYY hh:mm a')}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, v.agentCreditId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}