module.exports = {
    APILOCALURL: 'https://localhost:7086/api/',
    APISERVERURL: 'https://babaairapi.azurewebsites.net/api/',
    APIACTIVATEURL: 'https://babaairapi.azurewebsites.net/api/',
    

    LOGINUSER:'home/AdminLogin',
    GETCOUNTS:'Dashboard/GetCounts',
    UPDATECOMPANY:'Admin/Company',
    GETCOMPANY:'Admin/GetCompany',

    GETUSERS: 'User/GetUsers',
    GETCUSTOMERS: 'Admin/GetCustomers',
    CREATEUSER: 'User',
    UPDATEUSERSTATUS: 'User/UpdateStatus',
    DELETEUSER: 'User',
    GETUSERBYID: 'User/GetById',
    GETSTAFF:'User/GetUsers',

    GETHOTELBOOKINGS: 'Admin/GetHotelBookings',
    GETALLHOTELBOOKINGS: 'Admin/GetAllHotelBookings',
    GETHOTELBOOKINGBYID: 'Hotel/GetById',

    GETFLIGHTBOOKINGS: 'Admin/GetFlightBookings',
    GETALLFLIGHTBOOKINGS: 'Admin/GetAllFlightBookings',
    GETFLIGHTBOOKINGBYID: 'Flight/GetById',
  
    GETAGENTCREDITBYAGENTS: 'Admin/GetAgentCredits',
    GETAGENTWALLETBYAGENTS: 'Admin/GetAgentWallets',
    UPDATECREDIT: 'Admin/UpdateCredit',
    UPDATEWALLET: 'Admin/UpdateWallet',

    GETALLAGENTS: 'Agent/GetAll',
    GETAGENTS: 'Admin/GetAgents',
    CREATEAGENT: 'Home/RegisterAgent',
    UPDATEAGENT: 'Admin/UpdateAgent',
    DELETEAGENT: 'Agent',
    GETAGENTBYID: 'Agent/GetById',

    GETALLSUPPLIERS: 'Supplier/GetAll',
    GETSUPPLIERS: 'Supplier',
    CREATESUPPLIER: 'Supplier',
    UPDATESUPPLIER: 'Supplier',
    DELETESUPPLIER: 'Supplier',
    GETSUPPLIERBYID: 'Supplier/GetById',

    GETALLSUPPLIERCONTACTS: 'SupplierContact/GetAll',
    GETSUPPLIERCONTACTS: 'SupplierContact',
    CREATESUPPLIERCONTACT: 'SupplierContact/Create',
    UPDATESUPPLIERCONTACT: 'SupplierContact',
    DELETESUPPLIERCONTACT: 'SupplierContact',
    GETSUPPLIERCONTACTBYSUPPLIERID: 'SupplierContact/GetById',
    GETBYSUPPLIERID: 'SupplierContact/GetById',

    GETALLDESTINATIONS: 'Destination/GetAll',
    GETDESTINATIONS: 'Destination',
    CREATEDESTINATION: 'Destination/Create',
    UPDATEDESTINATION: 'Destination',
    DELETEDESTINATION: 'Destination',
    GETDESTINATIONBYID: 'Destination',

    SENDWHATSAPP:'Customer/SendWhatsApp',
    SENDSMS:'Customer/SendSMS',
    
    GETPROMOCODES: 'PromoCode',
    CREATEPROMOCODE: 'PromoCode/Create',
    UPDATEPROMOCODE: 'PromoCode',
    DELETEPROMOCODE: 'PromoCode',

    GETALLHOLIDAYENQUIRYS: 'HolidayEnquiry/GetAll',
    GETHOLIDAYENQUIRYS: 'HolidayEnquiry',
    CREATEHOLIDAYENQUIRY: 'HolidayEnquiry/Create',
    UPDATEHOLIDAYENQUIRY: 'HolidayEnquiry',
    DELETEHOLIDAYENQUIRY: 'HolidayEnquiry',
    GETHOLIDAYENQUIRYBYID: 'HolidayEnquiry',

    GETALLPACKAGEENQUIRYS: 'PackageEnquiry/GetAll',
    GETPACKAGEENQUIRYS: 'PackageEnquiry',
    CREATEPACKAGEENQUIRY: 'PackageEnquiry/Create',
    UPDATEPACKAGEENQUIRY: 'PackageEnquiry',
    DELETEPACKAGEENQUIRY: 'PackageEnquiry',
    GETPACKAGEENQUIRYBYID: 'PackageEnquiry',

    GETALLTOURS: 'Tour/GetAll',
    GETTOURS: 'Tour',
    CREATETOUR: 'Tour/Create',
    UPDATETOUR: 'Tour',
    DELETETOUR: 'Tour',
    GETTOURBYID: 'Tour',
    GETTOURBYDESTINATIONID: 'Tour/GetByDestinationId',
    
    CREATEINCLUSION: 'Tour/InsertPackageInclusion',
    UPDATEINCLUSION: 'Tour/UpdatePackageInclusion',
    DELETEINCLUSION: 'Tour/DeletePackageInclusion',
    
    CREATEEXCLUSION: 'Tour/InsertPackageExclusion',
    UPDATEEXCLUSION: 'Tour/UpdatePackageExclusion',
    DELETEEXCLUSION: 'Tour/DeletePackageExclusion',
    
    CREATEFLIGHTITINERARY: 'Tour/InsertFlightItinerary',
    UPDATEFLIGHTITINERARY: 'Tour/UpdatePackageFlight',
    DELETEFLIGHTITINERARY: 'Tour/DeletePackageFlight',
    
    CREATEHOTELITINERARY: 'Tour/InsertHotelItinerary',
    UPDATEHOTELITINERARY: 'Tour/UpdatePackageHotel',
    DELETEHOTELITINERARY: 'Tour/DeletePackageHotel',
    
    CREATEITINERARY: 'Tour/InsertPackageItinerary',
    UPDATEITINERARY: 'Tour/UpdatePackageItinerary',
    DELETEITINERARY: 'Tour/DeletePackageItinerary',


    GETALLPACKAGES: 'Package/GetAll',
    GETPACKAGES: 'Package',
    CREATEPACKAGE: 'Package/Create',
    UPDATEPACKAGE: 'Package',
    DELETEPACKAGE: 'Package',
    GETPACKAGEBYID: 'Package',
    GETPACKAGESBYDESTINATIONID: 'Package/GetByDestinationId',

    GETINCLUSIONS: 'PackageInclusion',    
    GETINCLUSIONRBYID: 'PackageInclusion',
    GETINCLUSIONBYPACKAGEID: 'PackageInclusion/GetByPackageId',

    GETEXCLUSIONS: 'PackageExclusion',    
    GETEXCLUSIONBYID: 'PackageExclusion',
    GETEXCLUSIONBYPACKAGEID: 'PackageExclusion/GetByPackageId',

    GETALLHOLIDAYITINERARY: 'HolidayItinerary',
    GETHOLIDAYITINERARY: 'HolidayItinerary',
    GETHOLIDAYITINERARYBYPACKAGEID: 'HolidayItinerary/GetByPackageId',
    CREATEHOLIDAYITINERARY: 'HolidayItinerary/Create',
    UPDATEHOLIDAYITINERARY: 'HolidayItinerary',
    DELETEHOLIDAYITINERARY: 'HolidayItinerary',
    GETHOLIDAYITINERARYBYID: 'HolidayItinerary',

    GETVISACOUNTRIES: 'Visa/GetVisaCountries',
    GETVISASBYCOUNTRYNAME: 'Visa/GetVisasByCountryName',
    GETVISASBYCOUNTRYID: 'Visa/GetVisasByCountryId',
    GETVISABYID: 'Visa',
    GETALLVISAS:'Visa/GetAllVisas',
    SENDVISATOCUSTOMERSMS:'Visa/SendSMS',
    SENDVISATOCUSTOMEREMAIL:'Visa/SendEmail',
    SENDVISATOCUSTOMERWHATSAPP:'Visa/SendWhatsApp',

    GETVISAPRICE: 'VisaPrice',
    GETVISAPRICEBYCOUNTRY: 'VisaPrice/GetByCountry',
    GETVISAPRICESBYCOUNTRYID: 'VisaPrice/GetByCountry',
    CREATEVISAPRICE: 'VisaPrice/Create',
    UPDATEVISAPRICE: 'VisaPrice',
    DELETEVISAPRICE: 'VisaPrice',
    GETVISAPRICEBYID: 'VisaPrice',


    GETBYVISAAPPLICANT: 'VisaApplicantDocument/VisaApplicant',
    CREATE: 'VisaApplicantDocument/Create',
    UPDATE: 'VisaApplicantDocument',
    DELETE: 'VisaApplicantDocument',
    GETBYID: 'VisaApplicantDocument',

    GETVISAREQUESTALL: 'VisaRequest/GetAll',
    GETALLVISAREQUESTNAMES: 'VisaRequest/GetAllNames',
    GETVISAREQUEST: 'VisaRequest',
    CREATEVISAREQUEST: 'VisaRequest/Create',
    UPDATEVISAREQUEST: 'VisaRequest',
    DELETEVISAREQUEST: 'VisaRequest',
    GETVISAREQUESTBYID: 'VisaRequest/GetByAdminId',

    UPDATEDVISASTATUS:'VisaApplicant/VisaStatus',

    GETTRANSACTIONS:'Report/GetTransactions',
    GETVISAREPORT:'Report/GetVisaRequests',
    GETFLIGHTREPORT:'Admin/FlightReport',
    GETHOTELREPORT:'Admin/HotelReport',
   
    GETTAXINFORMATIONS: 'TaxInformation',
    CREATETAXINFORMATION: 'TaxInformation/Create',
    UPDATETAXINFORMATION: 'TaxInformation',
    DELETETAXINFORMATION: 'TaxInformation',
    GETTAXINFORMATIONBYID: 'TaxInformation/GetById',

    GETCUSTOMERMARKUPS: 'CustomerMarkup',
    CREATECUSTOMERMARKUP: 'CustomerMarkup/Create',
    UPDATECUSTOMERMARKUP: 'CustomerMarkup',
    DELETECUSTOMERMARKUP: 'CustomerMarkup',
    GETCUSTOMERMARKUPBYID: 'CustomerMarkup/GetById',

    GETMARKUPS: 'Markup',
    CREATEMARKUP: 'Markup/Create',
    UPDATEMARKUP: 'Markup',

    GETPAYMENT: 'Payment',
    CREATEPAYMENT: 'Payment',
    UPDATEPAYMENT: 'Payment',

    GETALLUMRAHS: 'Umrah/GetAll',
    GETUMRAHS: 'Umrah',
    CREATEUMRAH: 'Umrah/Create',
    UPDATEUMRAH: 'Umrah',
    DELETEUMRAH: 'Umrah',
    GETUMRAHBYID: 'Umrah',
    GETUMRAHPRICES:'Umrah/GetUmrahPrices',
    
    CREATEUMRAHINCLUSION: 'Umrah/InsertPackageInclusion',
    UPDATEUMRAHINCLUSION: 'Umrah/UpdatePackageInclusion',
    DELETEUMRAHINCLUSION: 'Umrah/DeletePackageInclusion',
    
    CREATEUMRAHEXCLUSION: 'Umrah/InsertPackageExclusion',
    UPDATEUMRAHEXCLUSION: 'Umrah/UpdatePackageExclusion',
    DELETEUMRAHEXCLUSION: 'Umrah/DeletePackageExclusion',
    
    CREATEUMRAHFLIGHTITINERARY: 'Umrah/InsertFlightItinerary',
    UPDATEUMRAHFLIGHTITINERARY: 'Umrah/UpdatePackageFlight',
    DELETEUMRAHFLIGHTITINERARY: 'Umrah/DeletePackageFlight',
    
    CREATEUMRAHHOTELITINERARY: 'Umrah/InsertHotelItinerary',
    UPDATEUMRAHHOTELITINERARY: 'Umrah/UpdatePackageHotel',
    DELETEUMRAHHOTELITINERARY: 'Umrah/DeletePackageHotel', 

    GETUMRAHENQUIRYS: 'UmrahEnquiry',
    CREATEUMRAHENQUIRY: 'UmrahEnquiry/Create',
    UPDATEUMRAHENQUIRY: 'UmrahEnquiry',
    DELETEUMRAHENQUIRY: 'UmrahEnquiry'
  }