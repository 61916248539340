import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
const visaApplicantStatus = {
    visaApplicantId: "00000000-0000-0000-0000-000000000000",
    visaRequestId: "00000000-0000-0000-0000-000000000000",
    applicantStatus: "",
    visaRequestStatus: "",
    comments: "",
    approvedVisaSrc: '',
    approvedVisaFile: null,
};
export default function VisaRequestDetails() {
    const params = useParams();
    const [visaRequest, setVisaRequest] = useState('');
    const [visaApplicants, setVisaApplicants] = useState([]);
    const [visaApplicationDocuments, setVisaApplicationDocuments] = useState([]);
    const handleStatusChange = (visaApplicantId, e) => {
        let newArr = [...visaApplicants];
        newArr.forEach((task) => {
            if (task.visaApplicantId === visaApplicantId) {
                task.visaApplicantStatus = e.target.value;
            }
        });
        setVisaApplicants(newArr);
    };
    const handleCommentsChange = (visaApplicantId, e) => {
        let newArr = [...visaApplicants];
        newArr.forEach((task) => {
            if (task.visaApplicantId === visaApplicantId) {
                task.comments = e.target.value;
            }
        });
        setVisaApplicants(newArr);
    };
    const handleFileChange = (visaApplicantId, e) => {
        let newArr = [...visaApplicants];
        newArr.forEach((task) => {
            if (task.visaApplicantId === visaApplicantId) {
                if (e.target.files && e.target.files[0]) {
                    let imageFile = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = x => {
                        task.approvedVisaFile = imageFile;
                    }
                    reader.readAsDataURL(imageFile)
                }
            }
        });
        setVisaApplicants(newArr);
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetVisaRequestDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETVISAREQUESTBYID + "/" + params.visaRequestId, { ...headerconfig })
            .then((response) => {
                setVisaRequest(response.data.data);
                setVisaApplicants(response.data.data.visaApplicants);
                setVisaApplicationDocuments(response.data.data.visaApplicationDocuments);
            });
    };
    const SubmitStatus = (e, visadata) => {
        console.log(visadata)
        e.preventDefault();
        const formData = new FormData()
        formData.append("visaApplicantId", visadata.visaApplicantId)
        formData.append("visaRequestId", params.visaRequestId)
        formData.append("visaRequestStatus", visadata.visaApplicantStatus)
        formData.append("visaApplicantStatus", visadata.visaApplicantStatus)
        formData.append("comments", visadata.comments)
        formData.append("approvedVisaFile", visadata.approvedVisaFile)
        axios.post(config.APIACTIVATEURL + config.UPDATEDVISASTATUS, formData, { ...headerconfig })
            .then((response) => {
                handleSuccess(response.data.data);
                GetVisaRequestDetails();
            })
    };
    useEffect(() => {
        GetVisaRequestDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Visa Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Visa</b> : {visaRequest.visaName}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Country</b> : {visaRequest.countryName}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Travel Date</b> : {moment(visaRequest.travelDate).format('Do MMM yyyy')}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Applicants</b> : {visaRequest.noOfApplicants}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Price</b> : {visaRequest.pricePerApplicant} INR</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Total Price</b> : {visaRequest.totalPrice} INR</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>TransactionNo</b> : {visaRequest.transactionNo}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Payment Gateway Reference</b> : {visaRequest.paymentGatewayNo}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Payment Status</b> : {visaRequest.paymentStatus}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Source</b> : {visaRequest.leadSource}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Visa Status</b> :
                                            {visaRequest.visaRequestStatus === "NEW" ? <span className="badge bg-primary p-1" style={{ marginLeft: "10px" }}>{visaRequest.visaRequestStatus}</span> : visaRequest.visaRequestStatus === "APPROVED" ? <span className="text-bg-success p-1" style={{ marginLeft: "10px" }}>{visaRequest.visaRequestStatus}</span> : <span className="badge bg-danger p-1" style={{ marginLeft: "10px" }}>{visaRequest.visaRequestStatus}</span>}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Last Updated</b> : {moment(visaRequest.updatedDate).format('Do MMM yyyy hh:mm a')}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Comments</b> : {visaRequest.comments}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>Visa Applicants</h3>
                            </div>
                            <div className="card-body">
                                {visaApplicants.length > 0 && visaApplicants.map((v, index) =>
                                    <div className="accordion lefticon-accordion custom-accordionwithicon accordion-border-box accordion-success" id="default-accordion-example">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                    Applicant {parseInt(index + 1)} : {v.visaApplicantStatus === "NEW" ? <span className="badge bg-primary p-1" style={{ marginLeft: "10px" }}>{v.visaApplicantStatus}</span> : v.visaApplicantStatus === "APPROVED" ? <span className="badge bg-warning p-1" style={{ marginLeft: "10px" }}>{v.visaApplicantStatus}</span> : <span className="badge bg-danger p-1" style={{ marginLeft: "10px" }}>{v.visaApplicantStatus}</span>}
                                                </button>
                                            </h2>
                                            <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                                                <div className="accordion-body">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Name</b> : {v.title} {v.firstName} {v.lastName}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Gender</b> : {v.gender}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Date Of Birth</b> : {moment(v.dateOfBirth).format('Do MMM yyyy')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Nationality</b> : {v.nationality}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Mobile</b> : {v.mobile}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Email</b> : {v.email}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Passport Number</b> : {v.passportNumber}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Passport Issue Date</b> : {moment(v.passportIssueDate).format('Do MMM yyyy')}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Passport ExpireDate</b> : {moment(v.passportExpireDate).format('Do MMM yyyy')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li><img src={v.profileSrc} width='200px' height={200} className="mb-4"></img></li>
                                                                <li><Link to={v.profileSrc} target="_blank" className="btn btn-sm btn-primary"> View </Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li><img src={v.passportFrontSrc} width='200px' height={200} className="mb-4"></img></li>
                                                                <li><Link to={v.passportFrontSrc} target="_blank" className="btn btn-sm btn-primary"> View </Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li><img src={v.passportBackSrc} width='200px' height={200} className="mb-4"></img></li>
                                                                <li className="text-center"><Link to={v.passportBackSrc} target="_blank" className="btn btn-sm btn-primary"> View </Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3 mb-2">
                                                        <div className="col-xxl-3 col-lg-4">
                                                            <div className="form-floating">
                                                                <select name="visaApplicantStatus" className="form-select" onChange={e => handleStatusChange(v.visaApplicantId, e)} value={v.visaApplicantStatus} >
                                                                    <option value="New">NEW</option>
                                                                    <option value="Documents Pending">DOCUMENTS PENDING</option>
                                                                    <option value="Details Missing">DETAILS MISSING</option>
                                                                    <option value="Processing">PROCESSING</option>
                                                                    <option value="Approved">APPROVED</option>
                                                                    <option value="Rejected">REJECTED</option>
                                                                    <option value="Cancelled">CANCELLED</option>
                                                                </select>
                                                                <label className="status">Visa Status</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-lg-4">
                                                            <div className="picture-container">
                                                                <label className="status">Upload Approved Visa</label>
                                                                <input id="image-uploader" name="approvedVisaFile" className={"form-control-file"} type="file" onChange={e => handleFileChange(v.visaApplicantId, e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3 mb-2">
                                                        <div className="col-xxl-12 col-lg-6">
                                                            <div className="form-floating">
                                                                <textarea type="text" id="comments" className="form-control" name="comments" value={v.comments === "undefined" ? "" : v.comments} placeholder="Please enter comments" onChange={e => handleCommentsChange(v.visaApplicantId, e)} />
                                                                <label className="comments">Comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-xxl-2 col-lg-4">
                                                            <button className="btn btn-primary w-100" onClick={e => SubmitStatus(e, v)}>Update</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}