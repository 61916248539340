import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import Select from 'react-select';
import ReactDatePicker from "react-datepicker";
import moment from "moment";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    umrahPackageName: "",
    fromCity: "",
    toCity: "",
    totalDays: 15,
    nightsInMadina: 7,
    nightsInMakkah: 7,
    isFlightIncluded: true,
    isHotelIncluded: true,
    isAllMealsIncluded: true,
    isTransportIncluded: true,
    isZiyaratIncluded: true,
    isVisaIncluded: true,
    isDirectFlight: true,
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage,
    umrahFlights: [],
    umrahHotels: [],
    umrahInclusions: [],
    umrahExclusions: [],
    umrahPrices: [],
    umrahTerms: []
};
const tourFlightInitialFieldValues = {
    umrahFlightId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    airlineName: ""
};
const tourHotelInitialFieldValues = {
    umrahHotelId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    hotelName: "",
    city: ""
};
const tourPriceInitialFieldValues = {
    umrahPriceId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    adultPrice: 0,
    kidPrice: 0,
    infantPrice: 0,
    fromDate: new Date(),
    toDate: new Date()
};
const tourInclusionFieldValues = {
    umrahInclusionId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    inclusion: ""
};
const tourExclusionFieldValues = {
    umrahExclusionId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    exclusion: ""
};
const tourTermsInitialFieldValues = {
    umrahTermId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    terms: ""
};
export default function UmrahPackage() {
    const navigate = useNavigate();
    const [destinations, setDestinations] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [tfValues, setTFValues] = useState(tourFlightInitialFieldValues);
    const [thValues, setTHValues] = useState(tourHotelInitialFieldValues);
    const [tpValues, setTPValues] = useState(tourPriceInitialFieldValues);
    const [tiValues, setTIValues] = useState(tourInclusionFieldValues);
    const [teValues, setTEValues] = useState(tourExclusionFieldValues);
    const [ttValues, setTTValues] = useState(tourTermsInitialFieldValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [tourPriceState, setTourPriceState] = useState([]);
    const [tourFlightState, setTourFlightState] = useState([]);
    const [tourHotelState, setTourHotelState] = useState([]);
    const [tourTermState, setTourTermState] = useState([
        { id: 1, terms: 'All Prices quoted are per person and include airfare for illustrative delineation' },
        { id: 2, terms: 'Extra luggage other than mentioned on ticket would be paid by the pilgrim.' },
        { id: 3, terms: 'Unutilized services are Non-refundable.' },
    ]);
    const [tourInclusionState, setTourInclusionState] = useState([
        { id: 1, inclusion: 'Flight Ticket' },
        { id: 2, inclusion: 'Accomdation' },
        { id: 3, inclusion: 'Visa' },
    ]);
    const [tourExclusionState, setTourExclusionState] = useState([
        { id: 1, exclusion: 'Expenses of personal nature such as tips, telephone calls, laundry, etc.' },
        { id: 2, exclusion: 'Anything that is not specified in Inclusions' },
        { id: 3, exclusion: 'Peak / Festival Period Surcharge to be paid extra & will be notified at time of booking confirmation' },
    ]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        let val = parseInt(e.target.value, 10);
        if (isNaN(val)) {
            setValues({
                ...values,
                [name]: "",
            });
        } else {
            val = val >= 0 ? val : 0;
            setValues({
                ...values,
                [name]: val,
            });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleFlightInputChange = (e) => {
        const { name, value } = e.target;
        setTFValues({
            ...tfValues,
            [name]: value,
        });
    };
    const handleHotelInputChange = (e) => {
        const { name, value } = e.target;
        setTHValues({
            ...thValues,
            [name]: value,
        });
    };
    const handleTPInputChange = (e) => {
        const { name, value } = e.target;
        setTPValues({
            ...tpValues,
            [name]: value,
        });
    };
    const handleTIInputChange = (e) => {
        const { name, value } = e.target;
        setTIValues({
            ...tiValues,
            [name]: value,
        });
    };
    const handleTEInputChange = (e) => {
        const { name, value } = e.target;
        setTEValues({
            ...teValues,
            [name]: value,
        });
    };
    const handleTRInputChange = (e) => {
        const { name, value } = e.target;
        setTTValues({
            ...ttValues,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.umrahPackageName = values.umrahPackageName === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("umrahPackageId", values.umrahPackageId)
            formData.append("umrahPackageName", values.umrahPackageName)
            formData.append("fromCity", values.fromCity)
            formData.append("toCity", values.toCity)
            formData.append("totalDays", values.totalDays)
            formData.append("nightsInMadina", values.nightsInMadina)
            formData.append("nightsInMakkah", values.nightsInMakkah)
            formData.append("isFlightIncluded", values.isFlightIncluded)
            formData.append("isHotelIncluded", values.isHotelIncluded)
            formData.append("isAllMealsIncluded", values.isAllMealsIncluded)
            formData.append("isTransportIncluded", values.isTransportIncluded)
            formData.append("isVisaIncluded", values.isVisaIncluded)
            formData.append("isDirectFlight", values.isDirectFlight)
            formData.append("isZiyaratIncluded", values.isZiyaratIncluded)
            formData.append("imageFile", values.imageFile)
            formData.append("imageName", values.imageName)
            formData.append("umrahFlights", JSON.stringify(tourFlightState))
            formData.append("umrahHotels", JSON.stringify(tourHotelState))
            formData.append("umrahPrices", JSON.stringify(tourPriceState))
            formData.append("umrahTerms", JSON.stringify(tourTermState))
            formData.append("umrahInclusions", JSON.stringify(tourInclusionState))
            formData.append("umrahExclusions", JSON.stringify(tourExclusionState))
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEUMRAH, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('umrahPackageId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        setBtnSubmit(true);
                        navigate('/umrahpackages')
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setTPValues(tourPriceInitialFieldValues)
        setErrors({});
    };
    // Function to add a new item to the flight
    const addTFItem = () => {
        const newItem = { id: tourFlightState.length + 1, airlineName: tfValues.airlineName};
        setTourFlightState([...tourFlightState, newItem]);
        setTFValues(tourFlightInitialFieldValues)
    };
    const removeTFItem = (id) => {
        const updatedItems = tourFlightState.filter(item => item.id !== id);
        setTourFlightState(updatedItems);
    };

    // Function to add a new item to the hotel
    const addTHItem = () => {
        const newItem = { id: tourHotelState.length + 1, city: thValues.city, hotelName: thValues.hotelName, meals: thValues.meals, roomCategory: thValues.roomCategory, checkIn: moment(thValues.checkIn).format('YYYY-MM-DD HH:MM A'), checkOut: moment(thValues.arrivalDate).format('YYYY-MM-DD HH:MM A') };
        setTourHotelState([...tourHotelState, newItem]);
        setTHValues(tourHotelInitialFieldValues)
    };
    const removeTHItem = (id) => {
        const updatedItems = tourHotelState.filter(item => item.id !== id);
        setTourHotelState(updatedItems);
    };

    // Function to add a new item to the itinerary
    const addTPItem = (index) => {
        const newItem = { id: tourPriceState.length + 1, adultPrice: tpValues.adultPrice, kidPrice: tpValues.kidPrice, infantPrice: tpValues.infantPrice, fromDate: moment(tpValues.fromDate).format('YYYY-MM-DD'), toDate: moment(tpValues.toDate).format('YYYY-MM-DD')};
        setTourPriceState([...tourPriceState, newItem]);
        setTPValues(tourPriceInitialFieldValues)
    };
    // Function to remove an item from the itinerary
    const removeTPItem = (id) => {
        console.log(id)
        const updatedItems = tourPriceState.filter(item => item.id !== id);
        setTourPriceState(updatedItems);
    };

    // Function to add a new item to the inclusion
    const addTINItem = () => {
        const newItem = { id: tourInclusionState.length + 1, inclusion: tiValues.inclusion };
        console.log(newItem)
        setTourInclusionState([...tourInclusionState, newItem]);
        setTIValues(tourInclusionFieldValues)
    };
    // Function to remove an item from the array inclusion
    const removeTINItem = (id) => {
        console.log(id)
        const updatedItems = tourInclusionState.filter(item => item.id !== id);
        setTourInclusionState(updatedItems);
    };
    // Function to add a new item to the exclusion
    const addTEItem = () => {
        const newItem = { id: tourExclusionState.length + 1, exclusion: teValues.exclusion };
        console.log(newItem)
        setTourExclusionState([...tourExclusionState, newItem]);
        setTEValues(tourExclusionFieldValues)
    };
    // Function to remove an item from the array exclusion
    const removeTEItem = (id) => {
        console.log(id)
        const updatedItems = tourExclusionState.filter(item => item.id !== id);
        setTourExclusionState(updatedItems);
    };

    // Function to add a new item to the exclusion
    const addTTItem = () => {
        const newItem = { id: tourTermState.length + 1, terms: ttValues.terms };
        console.log(newItem)
        setTourTermState([...tourTermState, newItem]);
        setTTValues(tourTermsInitialFieldValues)
    };
    // Function to remove an item from the array exclusion
    const removeTTItem = (id) => {
        console.log(id)
        const updatedItems = tourTermState.filter(item => item.id !== id);
        setTourTermState(updatedItems);
    };
    const applyErrorClass = (field) => field in errors && errors[field] === false ? " form-control-danger" : "";
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Create Package</h4>
                                    <div className="hstack gap-2 justify-content-end">
                                        {
                                            btnSubmit === true ?
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">{values.umrahPackageId === "00000000-0000-0000-0000-000000000000" ? "Save" : "Update"}</button>
                                                :
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100" disabled>Please wait...</button>
                                        }
                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="alert alert-success">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-12">
                                            <div className="picture-container">
                                                <div className="picture mb-3">
                                                    <img src={values.imageSrc} className="picture-src" width="250px" height="250px" controls />
                                                </div>
                                                <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">Package Name</label>
                                                    <input type="text" value={values.umrahPackageName} name="umrahPackageName" onChange={handleInputChange} className={"form-control" + applyErrorClass('umrahPackageName')} placeholder="Amazing Dubai" />
                                                    {errors.umrahPackageName === false ? (<div className="validationerror">Please enter package name </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">From City</label>
                                                    <input type="text" value={values.fromCity} name="fromCity" onChange={handleInputChange} className={"form-control" + applyErrorClass('fromCity')} placeholder="Hyderabad" />
                                                    {errors.fromCity === false ? (<div className="validationerror">Please enter fromCity </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="toCity" className="form-label">To City</label>
                                                    <input type="text" value={values.toCity} name="toCity" onChange={handleInputChange} className={"form-control" + applyErrorClass('toCity')} placeholder="Jeddah" />
                                                    {errors.toCity === false ? (<div className="validationerror">Please enter toCity </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nights" className="form-label">Total Days</label>
                                                <select name="totalDays" value={values.totalDays} onChange={handleInputChange} className={"form-select" + applyErrorClass('totalDays')}>
                                                    <option value={1}>1 Day</option>
                                                    <option value={2}>2 Days</option>
                                                    <option value={3}>3 Days</option>
                                                    <option value={4}>4 Days</option>
                                                    <option value={5}>5 Days</option>
                                                    <option value={6}>6 Days</option>
                                                    <option value={7}>7 Days</option>
                                                    <option value={8}>8 Days</option>
                                                    <option value={9}>9 Days</option>
                                                    <option value={10}>10 Days</option>
                                                    <option value={11}>11 Days</option>
                                                    <option value={12}>12 Days</option>
                                                    <option value={13}>13 Days</option>
                                                    <option value={14}>14 Days</option>
                                                    <option value={15}>15 Days</option>
                                                </select>
                                                {errors.totalDays === false ? (<div className="validationerror">Please select nights </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nights" className="form-label">Days in Madina</label>
                                                <select name="nightsInMadina" value={values.nightsInMadina} onChange={handleInputChange} className={"form-select" + applyErrorClass('nightsInMadina')}>
                                                    <option value={1}>1 Day</option>
                                                    <option value={2}>2 Days</option>
                                                    <option value={3}>3 Days</option>
                                                    <option value={4}>4 Days</option>
                                                    <option value={5}>5 Days</option>
                                                    <option value={6}>6 Days</option>
                                                    <option value={7}>7 Days</option>
                                                    <option value={8}>8 Days</option>
                                                    <option value={9}>9 Days</option>
                                                    <option value={10}>10 Days</option>
                                                    <option value={11}>11 Days</option>
                                                    <option value={12}>12 Days</option>
                                                    <option value={13}>13 Days</option>
                                                    <option value={14}>14 Days</option>
                                                    <option value={15}>15 Days</option>
                                                </select>
                                                {errors.nightsInMadina === false ? (<div className="validationerror">Please select nightsInMadina </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nightsInMakkah" className="form-label">Days In Makkah</label>
                                                <select name="nightsInMakkah" value={values.nightsInMakkah} onChange={handleInputChange} className={"form-select" + applyErrorClass('nightsInMakkah')}>
                                                    <option value={1}>1 Day</option>
                                                    <option value={2}>2 Days</option>
                                                    <option value={3}>3 Days</option>
                                                    <option value={4}>4 Days</option>
                                                    <option value={5}>5 Days</option>
                                                    <option value={6}>6 Days</option>
                                                    <option value={7}>7 Days</option>
                                                    <option value={8}>8 Days</option>
                                                    <option value={9}>9 Days</option>
                                                    <option value={10}>10 Days</option>
                                                    <option value={11}>11 Days</option>
                                                    <option value={12}>12 Days</option>
                                                    <option value={13}>13 Days</option>
                                                    <option value={14}>14 Days</option>
                                                    <option value={15}>15 Days</option>
                                                </select>
                                                {errors.nightsInMakkah === false ? (<div className="validationerror">Please select nightsInMakkah </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isFlightIncluded" className="form-label">Is Flight Included</label>
                                                    <select name="isFlightIncluded" value={values.isFlightIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isFlightIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isHotelIncluded" className="form-label">Is Hotels Included</label>
                                                    <select name="isHotelIncluded" value={values.isHotelIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isHotelIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isAllMealsIncluded" className="form-label">Is Meals Included</label>
                                                    <select name="isAllMealsIncluded" value={values.isAllMealsIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isAllMealsIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isTransportIncluded" className="form-label">Is Transport Included</label>
                                                    <select name="isTransportIncluded" value={values.isTransportIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isTransportIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isVisaIncluded" className="form-label">Is Visa Included</label>
                                                    <select name="isVisaIncluded" value={values.isVisaIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isVisaIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isZiyaratIncluded" className="form-label">Is Ziyarat Included</label>
                                                    <select name="isZiyaratIncluded" value={values.isZiyaratIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isZiyaratIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isDirectFlight" className="form-label">Is Direct Flight?</label>
                                                    <select name="isDirectFlight" value={values.isDirectFlight} onChange={handleInputChange} className={"form-select" + applyErrorClass('isDirectFlight')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#arrow-flight" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Flights</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-hotel" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Hotels</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-overview" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Price Itinerary</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-profile" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-account" /></span>
                                                    <span className="d-none d-sm-block">Inclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-contact" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Exclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-terms" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Terms</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content text-muted">
                                            <div className="tab-pane active" id="arrow-flight" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Airline Name</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourFlightState.length > 0 && tourFlightState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.airlineName}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTFItem(v.id)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tfValues.airlineName} className="form-control" onChange={handleFlightInputChange} name="airlineName"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTFItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-hotel" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>City</th>
                                                            <th>Hotel Name</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourHotelState.length > 0 && tourHotelState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.city}</td>
                                                                <td>{v.hotelName}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTHItem(v.id)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tfValues.city} className="form-control" onChange={handleHotelInputChange} name="city"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.hotelName} className="form-control" onChange={handleHotelInputChange} name="hotelName"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTHItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-overview" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>From Date</th>
                                                            <th>To Date</th>
                                                            <th>Adult Price</th>
                                                            <th>Kid Price</th>
                                                            <th>Infant Price</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourPriceState.length > 0 && tourPriceState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    {v.fromDate}
                                                                </td>
                                                                <td>
                                                                    {v.toDate}
                                                                </td>
                                                                <td>
                                                                    {v.adultPrice}
                                                                </td>
                                                                <td>
                                                                    {v.kidPrice}
                                                                </td>
                                                                <td>
                                                                    {v.infantPrice}
                                                                </td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTPItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tpValues.fromDate} dateFormat="dd/MM/yyyy" onChange={(date) => setTPValues({ ...tpValues, ['fromDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tpValues.toDate} dateFormat="dd/MM/yyyy" onChange={(date) => setTPValues({ ...tpValues, ['toDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tpValues.adultPrice} className="form-control" onChange={handleTPInputChange} name="adultPrice"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tpValues.kidPrice} className="form-control" onChange={handleTPInputChange} name="kidPrice"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tpValues.infantPrice} className="form-control" onChange={handleTPInputChange} name="infantPrice"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTPItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-profile" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Inclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourInclusionState.length > 0 && tourInclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.inclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTINItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tiValues.inclusion} className="form-control" onChange={handleTIInputChange} name="inclusion"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTINItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-contact" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Exclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourExclusionState.length > 0 && tourExclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.exclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTEItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={teValues.exclusion} className="form-control" onChange={handleTEInputChange} name="exclusion"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTEItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-terms" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Terms</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourTermState.length > 0 && tourTermState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.terms}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTTItem(v.id)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={ttValues.terms} className="form-control" onChange={handleTRInputChange} name="terms"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTTItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}