import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import Select from 'react-select';
import ReactDatePicker from "react-datepicker";
import moment from "moment";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    tourId: "00000000-0000-0000-0000-000000000000",
    destinationId: "00000000-0000-0000-0000-000000000000",
    name: "",
    type: "",
    travelDate: "",
    returnDate: "",
    nights: 0,
    totalPurchasePrice: 0,
    totalSalePrice: 0,
    pricePerPersons: 1,
    status: "",
    notes: "",
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage,
    tourFlights: [],
    tourHotels: [],
    tourItineraries: [],
    tourInclusion: [],
    tourExclusion: [],
    tourTerms: []
};
const tourFlightInitialFieldValues = {
    packageFlightId: "00000000-0000-0000-0000-000000000000",
    airlines: "",
    departureDate: new Date(),
    arrivalDate: new Date(),
    locationFrom: "",
    locationTo: ""
};
const tourHotelInitialFieldValues = {
    packageHotelId: "00000000-0000-0000-0000-000000000000",
    city: "",
    checkIn: new Date(),
    checkOut: new Date(),
    hotelName: "",
    roomCategory: "",
    meals: ""
};
const tourInitialFieldValues = {
    packageItineraryId: "00000000-0000-0000-0000-000000000000",
    title: "",
    description: ""
};
const tourInclusionFieldValues = {
    packageInclusionId: "00000000-0000-0000-0000-000000000000",
    inclusion: ""
};
const tourExclusionFieldValues = {
    packageExclusionId: "00000000-0000-0000-0000-000000000000",
    exclusion: ""
};
const tourTermsInitialFieldValues = {
    terms: ""
};
export default function EditUmrahPackage() {
    const navigate = useNavigate();
    const [destinations, setDestinations] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [tfValues, setTFValues] = useState(tourFlightInitialFieldValues);
    const [thValues, setTHValues] = useState(tourHotelInitialFieldValues);
    const [tourValues, setTourValues] = useState(tourInitialFieldValues);
    const [tiValues, setTIValues] = useState(tourInclusionFieldValues);
    const [teValues, setTEValues] = useState(tourExclusionFieldValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [tourItineraryState, setTourItineraryState] = useState([]);
    const [tourFlightState, setTourFlightState] = useState([]);
    const [tourHotelState, setTourHotelState] = useState([]);
    const [tourTermState, setTourTermState] = useState([]);
    const [tourInclusionState, setTourInclusionState] = useState([]);
    const [tourExclusionState, setTourExclusionState] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        let val = parseInt(e.target.value, 10);
        if (isNaN(val)) {
            setValues({
                ...values,
                [name]: "",
            });
        } else {
            val = val >= 0 ? val : 0;
            setValues({
                ...values,
                [name]: val,
            });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleFlightInputChange = (e) => {
        const { name, value } = e.target;
        setTFValues({
            ...tfValues,
            [name]: value,
        });
    };
    const handleHotelInputChange = (e) => {
        const { name, value } = e.target;
        setTHValues({
            ...thValues,
            [name]: value,
        });
    };
    const handleTourInputChange = (e) => {
        const { name, value } = e.target;
        setTourValues({
            ...tourValues,
            [name]: value,
        });
    };
    const handleTIInputChange = (e) => {
        const { name, value } = e.target;
        setTIValues({
            ...tiValues,
            [name]: value,
        });
    };
    const handleTEInputChange = (e) => {
        const { name, value } = e.target;
        setTEValues({
            ...teValues,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.nights = values.nights === 0 ? false : true;
        temp.totalSalePrice = values.totalSalePrice === 0 ? false : true;
        temp.type = values.type === "0" ? false : true;
        temp.destinationId = values.destinationId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("tourId", values.tourId)
            formData.append("destinationId", values.destinationId)
            formData.append("name", values.name)
            formData.append("nights", values.nights)
            formData.append("type", values.type)
            formData.append("travelDate", moment(values.travelDate).format('YYYY-MM-DD'))
            formData.append("returnDate", moment(values.returnDate).format('YYYY-MM-DD'))
            formData.append("totalPurchasePrice", values.totalPurchasePrice)
            formData.append("totalSalePrice", values.totalSalePrice)
            formData.append("pricePerPersons", values.pricePerPersons)
            formData.append("status", values.status)
            formData.append("description", values.description)
            formData.append("imageName", values.imageName)
            formData.append("imageFile", values.imageFile)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            update: (newrecord) => axios.put(config.APIACTIVATEURL + config.UPDATETOUR, newrecord, { ...headerconfig }),
            createpackageinclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEINCLUSION, newrecord, { ...headerconfig }),
            updatepackageinclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEINCLUSION, newrecord, { ...headerconfig }),
            deletepackageinclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEINCLUSION, newrecord, { ...headerconfig }),
            createpackageexclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEEXCLUSION, newrecord, { ...headerconfig }),
            updatepackageexclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEEXCLUSION, newrecord, { ...headerconfig }),
            deletepackageexclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEEXCLUSION, newrecord, { ...headerconfig }),
            createtouritinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEITINERARY, newrecord, { ...headerconfig }),
            updatetouritinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEITINERARY, newrecord, { ...headerconfig }),
            deletetouritinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEITINERARY, newrecord, { ...headerconfig }),
            createflightitinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEFLIGHTITINERARY, newrecord, { ...headerconfig }),
            updateflightitinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEFLIGHTITINERARY, newrecord, { ...headerconfig }),
            deleteflightitinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEFLIGHTITINERARY, newrecord, { ...headerconfig }),
            createhotelitinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEHOTELITINERARY, newrecord, { ...headerconfig }),
            updatehotelitinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEHOTELITINERARY, newrecord, { ...headerconfig }),
            deletehotelitinerary: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEHOTELITINERARY, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        //resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setTourValues(tourInitialFieldValues);
        setTIValues(tourInclusionFieldValues);
        setTEValues(tourExclusionFieldValues);
        setTHValues(tourHotelInitialFieldValues);
        setTFValues(tourFlightInitialFieldValues);
        setErrors({});
    };
    const GetHolidayDestinations = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLDESTINATIONS, { ...headerconfig })
            .then((response) => {
                setDestinations(response.data.data);
            });
    };
    // Function to add a new item to the flight
    const submitTFItem = () => {
        const formData = new FormData()
        formData.append("packageFlightId", tfValues.packageFlightId)
        formData.append("tourId", params.tourId)
        formData.append("airlines", tfValues.airlines)
        formData.append("locationFrom", tfValues.locationFrom)
        formData.append("locationTo", tfValues.locationTo)
        formData.append("departureDate", moment(tfValues.departureDate).format('YYYY-MM-DD'))
        formData.append("arrivalDate", moment(tfValues.arrivalDate).format('YYYY-MM-DD'))
        if (tfValues.packageFlightId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createflightitinerary(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateflightitinerary(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTFItem = (item) => {
        setTFValues(item);
    };
    const removeTFItem = (item) => {
        const formData = new FormData()
        formData.append("packageFlightId", item.packageFlightId)
        formData.append("tourId", params.tourId)
        formData.append("airlines", tfValues.airlines)
        formData.append("locationFrom", tfValues.locationFrom)
        formData.append("locationTo", tfValues.locationTo)
        formData.append("departureDate", moment(tfValues.departureDate).format('YYYY-MM-DD'))
        formData.append("arrivalDate", moment(tfValues.arrivalDate).format('YYYY-MM-DD'))
        applicationAPI().deleteflightitinerary(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };

    // Function to add a new item to the hotel
    const submitTHItem = () => {
        const formData = new FormData()
        formData.append("packageHotelId", thValues.packageHotelId)
        formData.append("tourId", params.tourId)
        formData.append("city", thValues.city)
        formData.append("hotelName", thValues.hotelName)
        formData.append("roomCategory", thValues.roomCategory)
        formData.append("meals", thValues.meals)
        formData.append("checkIn", moment(thValues.checkIn).format('YYYY-MM-DD'))
        formData.append("checkOut", moment(thValues.checkOut).format('YYYY-MM-DD'))
        if (thValues.packageHotelId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createhotelitinerary(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updatehotelitinerary(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTHItem = (item) => {
        setTHValues(item);
    };
    const removeTHItem = (item) => {
        const formData = new FormData()
        formData.append("packageHotelId", item.packageHotelId)
        formData.append("tourId", params.tourId)
        formData.append("city", item.city)
        formData.append("hotelName", item.hotelName)
        formData.append("roomCategory", item.roomCategory)
        formData.append("meals", item.meals)
        formData.append("checkIn", moment(item.checkIn).format('YYYY-MM-DD'))
        formData.append("checkOut", moment(item.checkOut).format('YYYY-MM-DD'))
        applicationAPI().deletehotelitinerary(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };

    // Function to add a new item to the itinerary
    const submitTIItem = () => {
        const formData = new FormData()
        formData.append("packageItineraryId", tourValues.packageItineraryId)
        formData.append("tourId", params.tourId)
        formData.append("title", tourValues.title)
        formData.append("description", tourValues.description)
        if (tourValues.packageItineraryId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createtouritinerary(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updatetouritinerary(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTIItem = (item) => {
        setTourValues(item);
    };
    // Function to remove an item from the itinerary
    const removeTIItem = (item) => {
        const formData = new FormData()
        formData.append("packageItineraryId", item.packageItineraryId)
        formData.append("tourId", params.tourId)
        formData.append("title", item.title)
        formData.append("description", item.description)
        applicationAPI().deletetouritinerary(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    // Function to remove an item from the array inclusion
    const removeTINItem = (item) => {
        const formData = new FormData()
        formData.append("packageInclusionId", item.packageInclusionId)
        formData.append("tourId", params.tourId)
        formData.append("inclusion", item.inclusion)
        applicationAPI().deletepackageinclusion(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    const editTINItem = (item) => {
        setTIValues(item);
    };
    const submitTINItem = () => {
        const formData = new FormData()
        formData.append("packageInclusionId", tiValues.packageInclusionId)
        formData.append("tourId", params.tourId)
        formData.append("inclusion", tiValues.inclusion)
        if (tiValues.packageInclusionId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createpackageinclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updatepackageinclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    // Function to add a new item to the exclusion
    const editTEItem = (item) => {
        setTEValues(item)
    };
    const submitTEItem = () => {
        const formData = new FormData()
        formData.append("packageExclusionId", teValues.packageExclusionId)
        formData.append("tourId", params.tourId)
        formData.append("exclusion", teValues.exclusion)
        if (teValues.packageExclusionId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createpackageexclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updatepackageexclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    // Function to remove an item from the array exclusion
    const removeTEItem = (item) => {
        const formData = new FormData()
        formData.append("packageExclusionId", item.packageExclusionId)
        formData.append("tourId", params.tourId)
        formData.append("exclusion", item.exclusion)
        applicationAPI().deletepackageexclusion(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    const applyErrorClass = (field) => field in errors && errors[field] === false ? " form-control-danger" : "";
    const params = useParams();
    const GetTourDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETTOURBYID + "/" + params.tourId, { ...headerconfig })
            .then((response) => {
                setValues(response.data.data);
                //setTour(response.data.data);
                if (response.data.data != null) {
                    setTourFlightState(response.data.data.tourFlights);
                    setTourHotelState(response.data.data.tourHotels);
                    setTourItineraryState(response.data.data.tourItineraries);
                    setTourInclusionState(response.data.data.tourInclusions);
                    setTourExclusionState(response.data.data.tourExclusions);
                }
            });
    };
    useEffect(() => {
        GetHolidayDestinations();
        GetTourDetails();
    }, [])

    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Create Package</h4>
                                    <div className="hstack gap-2 justify-content-end">
                                        {
                                            btnSubmit === true ?
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">{params.tourId === "00000000-0000-0000-0000-000000000000" ? "Save" : "Update"}</button>
                                                :
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100" disabled>Please wait...</button>
                                        }
                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="alert alert-success">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-12">
                                            <div className="picture-container">
                                                <div className="picture mb-3">
                                                    <img src={values.imageSrc} className="picture-src" width="250px" height="250px" controls />
                                                </div>
                                                <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Destination</label>
                                                    <select name="destinationId" value={values.destinationId} onChange={handleInputChange} className={"form-select" + applyErrorClass('type')}>
                                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                        {destinations.length>0 && destinations.map(d=>
                                                            <option value={d.value}>{d.label}</option>
                                                        )}
                                                    </select>
                                                    {errors.destinationId === false ? (<div className="validationerror">Please select destination </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">Package Name</label>
                                                    <input type="text" value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Amazing Dubai" />
                                                    {errors.name === false ? (<div className="validationerror">Please enter package name </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">Tour Type</label>
                                                    <select name="type" value={values.type} onChange={handleInputChange} className={"form-select" + applyErrorClass('type')}>
                                                        <option value="0">Please Select</option>
                                                        <option value="HONEYMOON">Honeymoon</option>
                                                        <option value="FIXED">Fixed Tour</option>
                                                        <option value="FAMILY">Family Tour</option>
                                                        <option value="GROUP">Group Tour</option>
                                                        <option value="DAILY">Daily Tour</option>
                                                        <option value="CORPORATE">Corporate</option>
                                                    </select>
                                                    {errors.type === false ? (<div className="validationerror">Please select tourType </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="travelDate" className="form-label">Travel Date</label>
                                                <ReactDatePicker showIcon selected={values.travelDate} dateFormat="dd/MM/yyyy" onChange={(date) => setValues({ ...values, ['travelDate']: date })} />
                                                {errors.travelDate === false ? (<div className="validationerror">Please select travelDate </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="returnDate" className="form-label">Return Date</label>
                                                <ReactDatePicker showIcon selected={values.returnDate} dateFormat="dd/MM/yyyy" onChange={(date) => setValues({ ...values, ['returnDate']: date })} />
                                                {errors.returnDate === false ? (<div className="validationerror">Please select returnDate </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nights" className="form-label">Nights</label>
                                                <input type="number" name="nights" value={values.nights} onChange={handleInputChange} className={"form-control" + applyErrorClass('nights')}></input>                                                {errors.noOfNights === false ? (<div className="validationerror">Please select nights </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="netPrice" className="form-label">Purchase Price</label>
                                                    <input type="number" min={0} value={values.totalPurchasePrice} name="totalPurchasePrice" onChange={handleNumberChange} className={"form-control" + applyErrorClass('totalPurchasePrice')} placeholder="0" />
                                                    {errors.totalPurchasePrice === false ? (<div className="validationerror">Please enter supplier price </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="totalSalePrice" className="form-label">Sale Price</label>
                                                    <input type="number" min={0} value={values.totalSalePrice} name="totalSalePrice" onChange={handleNumberChange} className={"form-control" + applyErrorClass('totalSalePrice')} placeholder="0" />
                                                    {errors.totalSalePrice === false ? (<div className="validationerror">Please enter salePrice </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="pricePerPersons" className="form-label">Price Per Person(s)</label>
                                                    <select name="pricePerPersons" value={values.pricePerPersons} onChange={handleInputChange} className={"form-select" + applyErrorClass('pricePerPersons')}>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={5}>6</option>
                                                    </select>
                                                    {errors.pricePerPersons === false ? (<div className="validationerror">Please select persons </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Status</label>
                                                    <select name="status" value={values.status} onChange={handleInputChange} className={"form-select" + applyErrorClass('status')}>
                                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                        <option value="ACTIVE">ACTIVE</option>
                                                        <option value="INACTIVE">INACTIVE</option>
                                                    </select>
                                                    {errors.status === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="description" className="form-label">Description</label>
                                                    <input type="text" value={values.description} name="description" onChange={handleInputChange} className={"form-control" + applyErrorClass('description')} />
                                                    {errors.description === false ? (<div className="validationerror">Please enter description </div>) : ('')}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#arrow-flight" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Flights</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-hotel" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Hotels</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-overview" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Tour Itinerary</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-profile" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-account" /></span>
                                                    <span className="d-none d-sm-block">Inclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-contact" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Exclusions</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content text-muted">
                                            <div className="tab-pane active" id="arrow-flight" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Airlines</th>
                                                            <th>Location From</th>
                                                            <th>Location To</th>
                                                            <th>Departure Date</th>
                                                            <th>Arrival Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourFlightState.length > 0 && tourFlightState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.airlines}</td>
                                                                <td>{v.locationFrom}</td>
                                                                <td>{v.locationTo}</td>
                                                                <td>{moment(v.departureDate).format('DD MMM YYYY hh:mm a')}</td>
                                                                <td>{moment(v.arrivalDate).format('DD MMM YYYY hh:mm a')}</td>
                                                                <td>
                                                                <Link className="remove-item-btn pt-3" onClick={e => editTFItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTFItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tfValues.airlines} className="form-control" onChange={handleFlightInputChange} name="airlines"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.locationFrom} className="form-control" onChange={handleFlightInputChange} name="locationFrom"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.locationTo} className="form-control" onChange={handleFlightInputChange} name="locationTo"></input>
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tfValues.departureDate} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput onChange={(date) => setTFValues({ ...tfValues, ['departureDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tfValues.arrivalDate} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput onChange={(date) => setTFValues({ ...tfValues, ['arrivalDate']: date })} />
                                                            </td>
                                                            <td>
                                                            {tfValues.packageFlightId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTFItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTFItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-hotel" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>City</th>
                                                            <th>Hotel Name</th>
                                                            <th>Room Category</th>
                                                            <th>Meal</th>
                                                            <th>Check-In</th>
                                                            <th>Check-Out</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourHotelState.length > 0 && tourHotelState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.city}</td>
                                                                <td>{v.hotelName}</td>
                                                                <td>{v.roomCategory}</td>
                                                                <td>{v.meals}</td>
                                                                <td>{moment(v.checkIn).format('DD MMM YYYY')}</td>
                                                                <td>{moment(v.checkOut).format('DD MMM YYYY')}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn pt-3" onClick={e => editTHItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTHItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={thValues.city} className="form-control" onChange={handleHotelInputChange} name="city"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={thValues.hotelName} className="form-control" onChange={handleHotelInputChange} name="hotelName"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={thValues.roomCategory} className="form-control" onChange={handleHotelInputChange} name="roomCategory"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={thValues.meals} className="form-control" onChange={handleHotelInputChange} name="meals"></input>
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={thValues.checkIn} dateFormat="dd/MM/yyyy" onChange={(date) => setTHValues({ ...thValues, ['checkIn']: date })} />
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={thValues.checkOut} dateFormat="dd/MM/yyyy" onChange={(date) => setTHValues({ ...thValues, ['checkOut']: date })} />
                                                            </td>
                                                            <td>
                                                                {thValues.packageHotelId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTHItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTHItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-overview" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourItineraryState.length > 0 && tourItineraryState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.title}</td>
                                                                <td>{v.description}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn pt-3" onClick={e => editTIItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTIItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tourValues.title} className="form-control" onChange={handleTourInputChange} name="title"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tourValues.description} className="form-control" onChange={handleTourInputChange} name="description"></input>
                                                            </td>
                                                            <td>
                                                                {tourValues.packageItineraryId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTIItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTIItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-profile" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Inclusions</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourInclusionState.length > 0 && tourInclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.inclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn pt-3" onClick={e => editTINItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTINItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-danger" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tiValues.inclusion} className="form-control" onChange={handleTIInputChange} name="inclusion"></input>
                                                            </td>
                                                            <td>
                                                                {tiValues.packageInclusionId == "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTINItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTINItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-contact" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Exclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourExclusionState.length > 0 && tourExclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.exclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn pt-3" onClick={e => editTEItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTEItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={teValues.exclusion} className="form-control" onChange={handleTEInputChange} name="exclusion"></input>
                                                            </td>
                                                            <td>
                                                                {teValues.packageExclusionId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTEItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTEItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}