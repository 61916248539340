import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import Select from 'react-select'
const initialFieldValues = [{
    visaPriceId: "00000000-0000-0000-0000-000000000000",
    visaId: "00000000-0000-0000-0000-000000000000",
    supplierPrice: 0,
    netPrice: 0,
    gst: 0,
    salePrice: 0
}];
export default function VisaPrice() {
    //const [visaPrices, setVisaPrices] = useState([]);
    const [visaCountries, setVisaCountries] = useState([]);
    //const [visaCountryId, setVisaCountryId] = useState("00000000-0000-0000-0000-000000000000");
    const [values, setValues] = useState([]);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }

    const handleInputChange = (index) => e => {
        e.preventDefault();
        const newList = values;
        newList[index][e.target.name] = e.target.value
        setValues([]);
        setValues(values => [...values, ...newList])
    };
    const handleCountryChange = (country) => {
        setLoading(true);
        GetVisaPrices(country.visaCountryId);
        //setVisaCountryId(country.visaCountryId);
    };
    const validate = () => {
        let temp = {};
        temp.netPrice = values.netPrice === 0 ? false : true;
        temp.gst = values.gst === 0 ? false : true;
        temp.salePrice = values.salePrice === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(true);
            const formData = new FormData()
            formData.append("visaPriceId", values.visaPriceId)
            formData.append("visaId", values.visaId)
            formData.append("supplierPrice", values.supplierPrice)
            formData.append("netPrice", values.salePrice)
            formData.append("gst", values.gst)
            formData.append("salePrice", values.salePrice)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEVISAPRICE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEVISAPRICE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('visaPriceId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        //GetVisaPrices(visaCountryId);
                        setBtnSubmit(false);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(false);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(false);
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        //resetForm();
                        //GetVisaPrices(visaCountryId);
                        setBtnSubmit(false);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(false);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(false);
                });
        }
    };
    const resetForm = () => {
        //setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (e, data) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(true);
            const formData = new FormData()
            formData.append("visaPriceId", data.visaPriceId)
            formData.append("visaId", data.visaId)
            formData.append("supplierPrice", data.supplierPrice)
            formData.append("netPrice", data.salePrice)
            formData.append("gst", data.gst)
            formData.append("salePrice", data.salePrice)
            addOrEdit(formData);
        }
    };
    const GetVisaCountries = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETVISACOUNTRIES, { ...headerconfig })
            .then((response) => {
                setVisaCountries(response.data.data);
                setLoading(false);
            });
    };
    const GetVisaPrices = (visaCountryId) => {
        axios
            .get(config.APIACTIVATEURL + config.GETVISAPRICEBYCOUNTRY + "/" + visaCountryId, { ...headerconfig })
            .then((response) => {
                //setVisaPrices(response.data.data);
                setValues(response.data.data.visas);
                setLoading(false);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        setLoading(true);
        GetVisaCountries();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Visa Prices</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="visaCountryId" className="form-label">Countries</label>
                                            <Select options={visaCountries} onChange={(e) => handleCountryChange(e)} placeholder="Visa Country" />{loading === true ? " Loading Please wait..." : ""}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Visa Prices</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Visa</th>
                                                        <th>SupplierPrice</th>
                                                        <th>SalePrice</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {values.length > 0 && values.map((v, index) =>
                                                        <tr key={v.visaId}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <span className="text-body fw-medium mt-0 mb-1 fs-13">{v.visaName}</span><br></br>
                                                                <span className="text-reset fs-14 mb-0">ProcessingTime : {v.processingTime} Days</span><br></br>
                                                                <span className="text-reset fs-14 mb-0">Validity: {v.validity}</span><br></br>
                                                                <span className="text-reset fs-14 mb-0">Type: {v.visaType}</span>
                                                            </td>
                                                            <td><input type="text" value={v.supplierPrice} name="supplierPrice" onChange={handleInputChange(index)} className={"form-control" + applyErrorClass('supplierPrice')} placeholder="0" /></td>
                                                            <td><input type="text" value={v.salePrice} name="salePrice" onChange={handleInputChange(index)} className={"form-control" + applyErrorClass('salePrice')} placeholder="0" /></td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        {btnSubmit === false ?
                                                                            <button type="button" className="btn btn-sm btn-primary edit-item-btn" onClick={e => showEditDetails(e, v)}>UPDATE</button>
                                                                            :
                                                                            <button disabled type="button" className="btn btn-sm btn-primary edit-item-btn">Please wait...</button>
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}