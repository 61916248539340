import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";

const initialFieldValues = {
    agentId: "00000000-0000-0000-0000-000000000000",
    userId: "00000000-0000-0000-0000-000000000000",
    agencyName: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    location: "",
    gstNo: "",
    creditBalance: 0,
    flightCommission: 0,
    hotelCommission: 0,
    isActive: true,
    password: "Test@123",
    creditStatus: true
};
export default function Agents() {
    const [agents, setAgents] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const validate = () => {
        let temp = {};
        temp.agencyName = values.agencyName === "" ? false : true;
        temp.name = values.name === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        temp.city = values.city === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("agentId", values.agentId)
            formData.append("userId", values.userId)
            formData.append("name", values.name)
            formData.append("agencyName", values.agencyName)
            formData.append("email", values.email)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("city", values.city)
            formData.append("address", values.address)
            formData.append("isActive", values.isActive)
            formData.append("gstNo", values.gstNo)
            formData.append("password", values.password)
            formData.append("creditBalance", values.creditBalance)
            formData.append("creditStatus", values.creditStatus)
            formData.append("flightCommission", values.flightCommission)
            formData.append("hotelCommission", values.hotelCommission)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEAGENT, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.post(config.APIACTIVATEURL + config.UPDATEAGENT, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('agentId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetAgents(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetAgents(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetAgents(totalPages)
    }
    const GetFirstPageData = () => {
        GetAgents("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetAgents(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEAGENT + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetAgents(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        GetAgents(pageNumber);
                    }
                });
        }
    }
    const GetAgents = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETAGENTS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                if (response.data.response.data !== null) {
                    setAgents(response.data.response.data);
                    setPageNumber(response.data.response.pageNumber);
                    setPageSize(response.data.response.pageSize);
                    setTotalPages(response.data.response.totalPages);
                    setData(response.data.response.data);
                    setTotalRecords(response.data.response.totalRecords);
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetAgents(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Agents</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="agencyName" className="form-label">Agency Name</label>
                                            <input type="text" value={values.agencyName} name="agencyName" onChange={handleInputChange} className={"form-control" + applyErrorClass('agencyName')} placeholder="Please Enter Agency Name" />
                                            {errors.agencyName === false ? (<div className="validationerror">Please enter agency name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Please Enter Name" />
                                            {errors.name === false ? (<div className="validationerror">Please enter name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please Enter Email" />
                                            {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="mobile" className="form-label">Mobile</label>
                                            <input type="text" max={12} value={values.phoneNumber} name="phoneNumber" onChange={handleInputChange} className={"form-control" + applyErrorClass('phoneNumber')} placeholder="Please Enter Mobile" />
                                            {errors.phoneNumber === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">GST No</label>
                                            <input onInput={toInputUppercase} maxLength={15} type="text" value={values.gstNo} name="gstNo" onChange={handleInputChange} className={"form-control" + applyErrorClass('gstNo')} placeholder="Please Enter GST No" />
                                            {errors.gstNo === false ? (<div className="validationerror">Please enter gst no </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="flightCommission" className="form-label">Flight Commission</label>
                                            <input type="text" value={values.flightCommission} name="flightCommission" onChange={handleInputChange} className={"form-control" + applyErrorClass('flightCommission')} placeholder="Please Enter Commission" />
                                            {errors.flightCommission === false ? (<div className="validationerror">Please enter flight commission </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="hotelCommission" className="form-label">Hotel Commission</label>
                                            <input type="text" value={values.hotelCommission} name="hotelCommission" onChange={handleInputChange} className={"form-control" + applyErrorClass('hotelCommission')} placeholder="Please Enter Commission" />
                                            {errors.hotelCommission === false ? (<div className="validationerror">Please enter hotel commission </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="status" className="form-label">Status</label>
                                            <select name="isActive" value={values.isActive} onChange={handleInputChange} className={"form-select" + applyErrorClass('isActive')}>
                                                <option value={true}>ACTIVE</option>
                                                <option value={false}>INACTIVE</option>
                                            </select>
                                            {errors.isActive === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="creditStatus" className="form-label">Credit Status</label>
                                            <select name="creditStatus" value={values.creditStatus} onChange={handleInputChange} className={"form-select" + applyErrorClass('creditStatus')}>
                                                <option value={true}>ACTIVE</option>
                                                <option value={false}>INACTIVE</option>
                                            </select>
                                            {errors.creditStatus === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">City</label>
                                            <input type="text" value={values.city} name="city" onChange={handleInputChange} className={"form-control" + applyErrorClass('city')} placeholder="Please Enter City" />
                                            {errors.city === false ? (<div className="validationerror">Please enter city </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <input type="text" value={values.address} name="address" onChange={handleInputChange} className={"form-control" + applyErrorClass('address')} placeholder="Please Enter Address" />
                                            {errors.address === false ? (<div className="validationerror">Please enter address </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary">{values.agentId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Agents</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Agency</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Mobile</th>
                                                        <th>Flight Commission</th>
                                                        <th>Hotel Commission</th>
                                                        <th>City</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {agents.length > 0 && agents.map((v, index) =>
                                                        <tr key={v.agentId}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.agencyName}</td>
                                                            <td>{v.name}</td>
                                                            <td>{v.email}</td>
                                                            <td>{v.phoneNumber}</td>
                                                            <td>{v.flightCommission}</td>
                                                            <td>{v.hotelCommission}</td>
                                                            <td>{v.city}</td>
                                                            <td>
                                                                {v.isActive === true ? <span className="badge bg-success">ACTIVE</span> : <span className="badge bg-danger">INACTIVE</span>}
                                                            </td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(v)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{agents.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}