import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import Select from 'react-select';
import moment from "moment";
const initialFieldValues = {
    visaRequestId: "00000000-0000-0000-0000-000000000000",
    visaId: "00000000-0000-0000-0000-000000000000",
    leadSource: "OFFLINE",
    visaName: "",
    countryName: "",
    pricePerApplicant: 0,
    status: "NEW",
    travelDate: new Date(),
    noOfApplicants: 0,
    totalPrice: 0,
    customerName: "",
    mobile: "",
    email: "",
    userId: "00000000-0000-0000-0000-000000000000",
    sendsms: true,
    sendemail: true,
    sendwhatsapp: true
};
export default function Visas() {
    const navigate = useNavigate();
    const [visas, setVisas] = useState([]);
    const [visaCountries, setVisaCountries] = useState([]);
    const [visaCountryName, setVisaCountryName] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [sms, setsms] = useState(true);
    const [email, setemail] = useState(true);
    const [whatsapp, setwhatsapp] = useState(true);
    const [loading, setLoading] = useState(false);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleInputChange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleCountryChange = (country) => {
        GetVisas(country.visaCountryId);
    };
    const validate = () => {
        let temp = {};
        temp.customerName = values.customerName === "" ? false : true;
        temp.mobile = values.mobile === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e, c) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData()
            formData.append("visaRequestId", values.visaRequestId)
            formData.append("visaId", c.visaId)
            formData.append("leadSource", values.leadSource)
            formData.append("visaName", c.visaName)
            formData.append("countryName", visaCountryName)
            formData.append("pricePerApplicant", c.salePrice)
            formData.append("netPrice", parseFloat(c.salePrice * parseFloat(values.noOfApplicants)))
            formData.append("gst", 0)
            formData.append("totalPrice", parseFloat(c.salePrice * parseFloat(values.noOfApplicants)))
            formData.append("visaRequestStatus", values.status)
            formData.append("travelDate", moment(values.travelDate).format('YYYY-MM-DD'))
            formData.append("noOfApplicants", parseFloat(values.noOfApplicants))
            formData.append("userId", values.userId)
            formData.append("customerName", values.customerName)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("visaRequestStatus", "NEW")
            formData.append("sendsms", sms)
            formData.append("sendemail", email)
            formData.append("sendwhatsapp", whatsapp)
            axios.post(config.APIACTIVATEURL + config.CREATEVISAREQUEST, formData, { ...headerconfig })
                .then((res) => {
                    handleSuccess("Record Created");
                    navigate('/visarequests')
                })
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const GetVisaCountries = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETVISACOUNTRIES, { ...headerconfig })
            .then((response) => {
                setVisaCountries(response.data.data);
                setLoading(false);
            });
    };
    const GetVisas = (visaCountryId) => {
        axios
            .get(config.APIACTIVATEURL + config.GETVISAPRICESBYCOUNTRYID + "/" + visaCountryId, { ...headerconfig })
            .then((response) => {
                if (response.data.data != undefined) {
                    setVisaCountryName(response.data.data.name)
                    setVisas(response.data.data.visas);
                    console.log(response.data.data.visas);
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        setLoading(true);
        GetVisaCountries();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Visas</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor="visaCountryId" className="form-label">Countries {loading === true ? "Loading Please wait..." : "Loading"}</label>
                                        <Select options={visaCountries} onChange={(e) => handleCountryChange(e)} placeholder="Visa Countries" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="visaCountryId" className="form-label">No Of Applicants</label>
                                    <select class="form-select" name="noOfApplicants" onChange={handleInputChange} value={values.noOfApplicants}>
                                        <option value={1}>1 Applicant</option>
                                        <option value={2}>2 Applicants</option>
                                        <option value={3}>3 Applicants</option>
                                        <option value={4}>4 Applicants</option>
                                        <option value={5}>5 Applicants</option>
                                        <option value={6}>6 Applicants</option>
                                        <option value={7}>7 Applicants</option>
                                        <option value={8}>8 Applicants</option>
                                        <option value={9}>9 Applicants</option>
                                    </select>
                                    {errors.noOfApplicants === false ? (<div className="validationerror">Please select applicants </div>) : ('')}
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-check mb-3"></div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" defaultChecked={setsms} onChange={() => setsms((state) => !state)} name="sms" />
                                        <label className="form-check-label">SEND SMS</label>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-check mb-3"></div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" defaultChecked={setemail} onChange={() => setemail((state) => !state)} name="email" />
                                        <label className="form-check-label">SEND EMAIL</label>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-check mb-3"></div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" defaultChecked={setwhatsapp} onChange={() => setwhatsapp((state) => !state)} name="whatsapp" />
                                        <label className="form-check-label">SEND WHATSAPP</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor="customerName" className="form-label">Customer Name</label>
                                        <input type="text" value={values.customerName} name="customerName" onChange={handleInputChange} className={"form-control" + applyErrorClass('customerName')} placeholder="Please enter customer name" />
                                        {errors.customerName === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor="mobile" className="form-label">Mobile</label>
                                        <input type="text" max={10} value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                        {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                        {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Visas</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Visa</th>
                                                        <th>Type</th>
                                                        <th>Supplier Price</th>
                                                        <th>Sale Price</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {visas.length > 0 && visas.map((v, index) =>
                                                        <tr key={v.visaId}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <span className="text-body fw-medium mt-0 mb-1 fs-13">{v.visaName}</span><br></br>
                                                                <span className="text-reset fs-14 mb-0">ProcessingTime : {v.processingTime} Days</span><br></br>
                                                                <span className="text-reset fs-14 mb-0">Validity: {v.validity}</span><br></br>
                                                            </td>
                                                            <td>{v.visaType}</td>
                                                            <td>{v.supplierPrice}</td>
                                                            <td>{v.salePrice}</td>
                                                            <td>
                                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                                    <button onClick={e => handleSubmit(e, v)} className="btn btn-sm btn-primary ms-auto">Send</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}