import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const defaultPBImage = "/assets/images/default_holiday.jpg";
export default function ViewTour() {
    const navigate = useNavigate();
    const params = useParams();
    const [tour, setTour] = useState({});
    const [flights, setFlights] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [tourItinerary, setTourItinerary] = useState([]);
    const [inclusions, setInclusions] = useState([]);
    const [exclusions, setExclusions] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const contentRef = useRef(null);

    const downloadPDF = () => {
        const input = contentRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save(tour.tourUrl + '.pdf');
        });
    };

    const GetTourDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETTOURBYID + "/" + params.tourId, { ...headerconfig })
            .then((response) => {
                console.log(response.data.data);
                setTour(response.data.data);
                if (response.data.data != null) {
                    setFlights(response.data.data.tourFlights);
                    setHotels(response.data.data.tourHotels);
                    setTourItinerary(response.data.data.tourItineraries);
                    setInclusions(response.data.data.tourInclusions);
                    setExclusions(response.data.data.tourExclusions);
                }
            });
    };
    useEffect(() => {
        GetTourDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center mt-2 mb-2">
                                            <div className="hstack gap-2 justify-content-right">
                                                <button type="button" className="btn btn-primary btn-sm" onClick={downloadPDF}>Download PDF</button>
                                                <button type="button" className="btn btn-success btn-sm">Share</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <img src={tour.imageSrc} style={{ width: "100%", height: "300px" }}></img>
                                                </div>
                                            </div>
                                            <h4>Tour Details</h4>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">Tour Name : {tour.name}</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">Destination : {tour.destinationName}</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">Nights : {tour.nights} Nights {tour.nights + 1} Days</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="travelDate" className="form-label">Travel Date : {moment(tour.travelDate).format('DD MMM YYYY')}</label>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="returnDate" className="form-label">Return Date : {moment(tour.returnDate).format('DD MMM YYYY')}</label>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="netPrice" className="form-label">Purchase Price : {tour.totalPurchasePrice}</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="totalSalePrice" className="form-label">Sale Price : {tour.totalSalePrice}</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="pricePerPersons" className="form-label">Price Per Person(s) : {tour.pricePerPersons}</label>
                                                </div>
                                            </div>
                                            {tour.description === "undefined" ? "" :
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="description" className="form-label">Description : {tour.description}</label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="tab-pane active" id="arrow-flight" role="tabpanel">
                                            <h5>Flights</h5>
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Airlines</th>
                                                        <th>Location From</th>
                                                        <th>Location To</th>
                                                        <th>Departure Date</th>
                                                        <th>Arrival Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {flights.length > 0 && flights.map((v, index) =>
                                                        <tr key={index}>
                                                            <td>{v.airlines}</td>
                                                            <td>{v.locationFrom}</td>
                                                            <td>{v.locationTo}</td>
                                                            <td>{moment(v.departureDate).format('DD MMM YYYY hh:mm a')}</td>
                                                            <td>{moment(v.arrivalDate).format('DD MMM YYYY hh:mm a')}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="tab-pane active" id="arrow-hotel" role="tabpanel">
                                            <h5>Hotels</h5>
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>City</th>
                                                        <th>Hotel Name</th>
                                                        <th>Room Category</th>
                                                        <th>Meal</th>
                                                        <th>Check-In</th>
                                                        <th>Check-Out</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {hotels.length > 0 && hotels.map((v, index) =>
                                                        <tr key={index}>
                                                            <td>{v.city}</td>
                                                            <td>{v.hotelName}</td>
                                                            <td>{v.roomCategory}</td>
                                                            <td>{v.meals}</td>
                                                            <td>{moment(v.checkIn).format('DD MMM YYYY hh:mm a')}</td>
                                                            <td>{moment(v.checkOut).format('DD MMM YYYY hh:mm a')}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="tab-pane active" id="arrow-overview" role="tabpanel">
                                            <h5>Itinerary</h5>
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {tourItinerary.length > 0 && tourItinerary.map((v, index) =>
                                                        <tr key={index}>
                                                            <td>{v.title}</td>
                                                            <td>{v.description}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="tab-pane active" id="arrow-profile" role="tabpanel">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Inclusions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {inclusions.length > 0 && inclusions.map((v, index) =>
                                                        <tr key={index}>
                                                            <td>{v.inclusion}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="tab-pane active" id="arrow-contact" role="tabpanel">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Exclusions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {exclusions.length > 0 && exclusions.map((v, index) =>
                                                        <tr key={index}>
                                                            <td>{v.exclusion}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>
                        </div>
                        <div ref={contentRef} style={{visibility:"hidden"}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="text-center mt-4 mb-5">
                                                <h5 className="fs-16">{tour.name}</h5>
                                                <p className="text-muted">{tour.destinationName} - {tour.nights} Nights {tour.nights + 1} Days</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="type" className="form-label">Tour Type : {tour.type}</label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label htmlFor="travelDate" className="form-label">Travel Date : {moment(tour.travelDate).format('DD MMM YYYY')}</label>
                                                </div>
                                                <div className="col-3">
                                                    <label htmlFor="returnDate" className="form-label">Return Date : {moment(tour.returnDate).format('DD MMM YYYY')}</label>
                                                </div>
                                                <div className="col-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="totalSalePrice" className="form-label">Price : INR {tour.totalSalePrice} per {tour.pricePerPersons} person(s)</label>
                                                    </div>
                                                </div>
                                                {tour.description === "undefined" ? "" :
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="description" className="form-label">Description : {tour.description}</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="tab-pane active" id="arrow-flight" role="tabpanel">
                                                <h5>Flights</h5>
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Airlines</th>
                                                            <th>Location From</th>
                                                            <th>Location To</th>
                                                            <th>Departure Date</th>
                                                            <th>Arrival Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {flights.length > 0 && flights.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.airlines}</td>
                                                                <td>{v.locationFrom}</td>
                                                                <td>{v.locationTo}</td>
                                                                <td>{moment(v.departureDate).format('DD MMM YYYY hh:mm a')}</td>
                                                                <td>{moment(v.arrivalDate).format('DD MMM YYYY hh:mm a')}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-pane active" id="arrow-hotel" role="tabpanel">
                                                <h5>Hotels</h5>
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>City</th>
                                                            <th>Hotel Name</th>
                                                            <th>Room Category</th>
                                                            <th>Meal</th>
                                                            <th>Check-In</th>
                                                            <th>Check-Out</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {hotels.length > 0 && hotels.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.city}</td>
                                                                <td>{v.hotelName}</td>
                                                                <td>{v.roomCategory}</td>
                                                                <td>{v.meals}</td>
                                                                <td>{moment(v.checkIn).format('DD MMM YYYY hh:mm a')}</td>
                                                                <td>{moment(v.checkOut).format('DD MMM YYYY hh:mm a')}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-pane active" id="arrow-overview" role="tabpanel">
                                                <h5>Itinerary</h5>
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourItinerary.length > 0 && tourItinerary.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.title}</td>
                                                                <td>{v.description}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-pane active" id="arrow-profile" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Inclusions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {inclusions.length > 0 && inclusions.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.inclusion}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-pane active" id="arrow-contact" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Exclusions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {exclusions.length > 0 && exclusions.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.exclusion}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>{/* end card-body */}
                                    </div>{/* end card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}