import { Link } from 'react-router-dom';

export default function LayoutSideBar() {
  return (
    <div className="app-menu navbar-menu">
      {/* LOGO */}
      <div className="navbar-brand-box">
        {/* Dark Logo*/}
        <Link to={"/"} className="logo logo-light">
          <span className="logo-lg" style={{ color: "#fff", fontSize: "20px" }}>
            BACK OFFICE
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          {
            localStorage.getItem('roleName') === "MANAGEMENT" ?
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link menu-link" to={'/dashboard'}>
                    <i className="ri-store-line"></i>
                    <span data-key="t-dashboards">Dashboard</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link menu-link" href="#sidebarHolidays" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarHolidays">
                    <i class="ri-luggage-cart-fill"></i> <span data-key="t-authentication">Holidays</span>
                  </a>
                  <div className="collapse menu-dropdown" id="sidebarHolidays">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/destinations'}>
                          <span data-key="t-dashboards">Destinations</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/tours'}>
                          <span data-key="t-dashboards">Tours</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/tourenquiry'}>
                          <span data-key="t-dashboards">Enquiries</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link menu-link" href="#sidebarUmrah" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarUmrah">
                    <i class="ri-luggage-cart-fill"></i> <span data-key="t-authentication">Umrah</span>
                  </a>
                  <div className="collapse menu-dropdown" id="sidebarUmrah">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/umrahpackages'}>
                          <span data-key="t-dashboards">Umrah</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/umrahpackage'}>
                          <span data-key="t-dashboards">Create</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/umrahenquiries'}>
                          <span data-key="t-dashboards">Enquiries</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link menu-link" href="#sidebarFlights" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarFlights">
                    <i class="ri-flight-takeoff-line"></i> <span data-key="t-authentication">Flights</span>
                  </a>
                  <div className="collapse menu-dropdown" id="sidebarFlights">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/flightbookings'}>
                          <span data-key="t-dashboards">Bookings</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/flight-report'}>
                          <span data-key="t-dashboards">Report</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link menu-link" href="#sidebarFlights" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarFlights">
                    <i class="ri-building-line"></i> <span data-key="t-authentication">Hotels</span>
                  </a>
                  <div className="collapse menu-dropdown" id="sidebarFlights">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/hotelbookings'}>
                          <span data-key="t-dashboards">Bookings</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/hotel-report'}>
                          <span data-key="t-dashboards">Report</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link menu-link" href="#sidebarSettings" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarSettings">
                    <i class="ri-folder-settings-line"></i><span data-key="t-authentication">Settings</span>
                  </a>
                  <div className="collapse menu-dropdown" id="sidebarSettings">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/users'}>
                          <span data-key="t-dashboards">Users</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/company'}>
                          <span data-key="t-dashboards">Company</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/promocodes'}>
                          <span data-key="t-dashboards">PromoCodes</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/markups'}>
                          <span data-key="t-dashboards">Markups</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/suppliers'}>
                          <span data-key="t-dashboards">Suppliers</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/paymentgateway'}>
                          <span data-key="t-dashboards">PaymentGateway</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/customers'}>
                          <span data-key="t-dashboards">Customers</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link menu-link" href="#sidebarAgents" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAgents">
                    <i class="ri-folder-settings-line"></i><span data-key="t-authentication">Agents</span>
                  </a>
                  <div className="collapse menu-dropdown" id="sidebarAgents">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/agents'}>
                          <span data-key="t-dashboards">Agents</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/agentcredits'}>
                          <span data-key="t-dashboards">Agent Credits</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link" to={'/agentwallets'}>
                          <span data-key="t-dashboards">Agent Wallets</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to={"/logout"} className="nav-link menu-link"><i className="mdi mdi-logout" /> <span className="align-middle" data-key="t-logout">Logout</span></Link>
                </li>
              </ul>
              :
              localStorage.getItem('roleName') === "VISA" ?
                <ul>

                </ul>
                :
                  ""}
        </div>
      </div>
      <div className="sidebar-background" />
    </div>
  );
}
